.TasksReportComponent {
  height: 212px;
  width: 50%;
  .custom-metric-card {
    height: 58px;
    border: unset;
    width: 250px;
    padding: 0px;
    .metric-card__icon {
      margin-top: -3px;
    }
  }
}

.TasksReportComponent .ProgressContainer {
  .progress-bar {
    display: flex;
    height: 32px;
    margin-bottom: 1px;
    &__title {
      min-width: 150px;
      line-height: 32px !important;
    }
    &__bar-wrapper {
      position: relative;
      width: calc(90% - 150px);
      &__bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        background-color: var(--brand_primary);
        &.--state-2 {
          background-color: var(--blue_2);
        }
        &.--state-3 {
          background-color: var(--platinum_3);
        }
        &__value {
          position: absolute;
          top: 50%;
          right: -24px;
          transform: translateY(-50%);
          &.--state-3 {
          }
        }
      }
      &__value {
        position: absolute;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
      }
      &.--state-3 {
        background-image: url('/images/SF-Jobs-Reporst.bg.png');
      }
    }
  }
}

.TasksReportComponent.small-mode {
  height: auto;
  .ProgressContainer {
    .progress-bar {
      &__title {
        min-width: 120px;
      }
      &__bar-wrapper {
        min-width: 185px;
      }
    }
  }
}
